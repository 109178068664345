.cdk-global-overlay-wrapper {
  // mat-dialog
  .cdk-overlay-pane {
    --mat-dialog-container-min-width: 90%;

    .mdc-dialog__title::before {
      display: none;
    }

    .mat-mdc-dialog-container {
      min-width: 100%;
      overflow: hidden;
    }

    .mat-mdc-dialog-content {
      max-height: 100%;
    }

    .mat-mdc-dialog-actions {
      --mat-dialog-actions-padding: 1rem 1.5rem;
      box-sizing: border-box;
      margin: 0;
      padding: 1rem 1.5rem;
      min-height: 60px;
    }

    .modal-header {
      --bs-modal-header-border-width: 1px;
      --bs-modal-header-border-color: var(--bs-border-default);
      --bs-modal-header-padding: 1rem;
    }

    .modal-footer {
      --bs-modal-footer-border-width: 1px;
      --bs-modal-footer-border-color: var(--bs-border-default);

      border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color) !important;

      &.border-0 {
        border-top: 0 !important;
      }
    }

    &.p-0 {
      .mat-mdc-dialog-container {
        padding: 0;
      }
    }

    &.overview {
      .mat-mdc-dialog-content {
        overflow: visible;
      }
    }

    &.small {
      --mat-dialog-container-min-width: 90%;

      @include min-width-screen($min-medium) {
        --mat-dialog-container-min-width: clamp(300px, 50vw, 400px);
        --mat-dialog-container-max-width: clamp(fit-content, 50vw, 400px);

        &.not-fit-content {
          --mat-dialog-container-max-width: clamp(300px, 50vw, 400px);
        }
      }

      &.h-max-content {
        .mat-mdc-dialog-container {
          height: max-content;
        }
      }
    }

    &.medium {
      --mat-dialog-container-min-width: 90%;

      @include min-width-screen($min-medium) {
        --mat-dialog-container-min-width: clamp(600px, 50vw, 650px);
        --mat-dialog-container-max-width: clamp(600px, 50vw, 650px);
      }

      &.h-max-content {
        .mat-mdc-dialog-container {
          height: max-content;
        }
      }
    }

    &.min-width {
      --mat-dialog-container-min-width: 90%;

      @include min-width-screen($min-medium) {
        --mat-dialog-container-min-width: clamp(600px, 50vw, 600px);
      }
    }

    &.default {
      --mat-dialog-container-min-width: 90%;

      @include min-width-screen($min-medium) {
        --mat-dialog-container-min-width: clamp(600px, 50vw, 768px);
        --mat-dialog-container-max-width: clamp(600px, 50vw, 768px);
      }

      @include min-width-screen($min-large) {
        --mat-dialog-container-min-width: clamp(768px, 50vw, 992px);
        --mat-dialog-container-max-width: clamp(768px, 50vw, 992px);
      }

      .mat-mdc-dialog-container {
        height: 90dvh;
      }

      &.h-max-content {
        .mat-mdc-dialog-container {
          height: max-content;
        }
      }
    }
  }
}
