$primary: #9f8e7a;
$primary-light: #a0907a;
$primary-dark: #897a68;
$secondary: #e7cfb4;
$secondary-light: rgba($secondary, 0.33);
$secondary-dark: #e0c3a4;
$light: #f4f4f5;
$page-bg: #f7f9fd;
$purple: #747cf3;
$white: white;
$black: black;
$dark: #1f2937;
$gray: #8e9aa0;
$title-bg: #f4f4f7;
$dark-red: #ca5b5b;
$note: #af3f40;
$blue: #4389f4;

$dark-blue: #2a2a49;
$middle: #9ca3af;
$dark-gray: #767676;
$box-shadow: rgba($primary-light, 0.05);

$danger: #ef4444;
$warning: #f59e0b;
$green: #10b981;
$orange: #b85921;

$success: $green;
$success-light: rgba(
  $color: $success,
  $alpha: 0.33,
);

$error: $danger;
$error-light: rgba(
  $color: $error,
  $alpha: 0.33,
);

$pending: $warning;
$pending-light: rgba(
  $color: $pending,
  $alpha: 0.33,
);
