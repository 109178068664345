@media print {
  * {
    margin: 0 !important;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section,
  main,
  div {
    &:not(.row, [class*="flex"], .not-printed) {
      display: block !important;
    }
  }

  body {
    background-color: white !important;
    print-color-adjust: exact !important;
    line-height: 1.33;
    overflow: auto !important;
  }

  main {
    overflow: unset !important;
    height: 100% !important;
  }

  app-root,
  app-layout {
    display: block;
  }

  app-navbar,
  app-sidebar {
    display: none !important;
  }

  app-admin {
    padding: 0 !important;
    background-color: white !important;
  }

  table {
    /* tables don't split across pages if possible. */
    page-break-inside: initial !important;

    /* This is the key */
    thead {
      display: table-header-group !important;
    }

    tfoot {
      display: table-footer-group !important;
    }
  }

  @page {
    size: A4;
    margin: 0.8cm 0 0;
  }

  .page-wrapper {
    &.print {
      overflow: unset !important;
      padding-top: 0 !important;
      margin: 0 !important;
      height: 100%;
    }

    .print-title-wrapper {
      page-break-inside: initial !important;

      .print-title {
        width: 100% !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        font-size: 32pt !important;
      }
    }

    .print-body {
      margin: 0 !important;

      .mat-tab-body-wrapper {
        margin: 0 !important;
      }
    }

    .table-row {
      page-break-before: auto !important;
      page-break-inside: initial !important;
      page-break-after: auto !important;
    }
  }

  .not-printed,
  mat-tab-header {
    display: none !important;
  }

  .total-income {
    display: flex !important;
    top: -48px !important;

    .amount {
      width: fit-content !important;
    }
  }
}
