@font-face {
  font-family: 'databoat-font';
  src:  url('fonts/databoat-font.eot?e7an4i');
  src:  url('fonts/databoat-font.eot?e7an4i#iefix') format('embedded-opentype'),
    url('fonts/databoat-font.ttf?e7an4i') format('truetype'),
    url('fonts/databoat-font.woff?e7an4i') format('woff'),
    url('fonts/databoat-font.svg?e7an4i#databoat-font') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="db-icon-"], [class*=" db-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'databoat-font' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.db-icon-menu-lines:before {
  content: "\e95a";
}
.db-icon-profile:before {
  content: "\e959";
}
.db-icon-font:before {
  content: "\e905";
  color: #8e9aa0;
}
.db-icon-dark-mode:before {
  content: "\e907";
  color: #8e9aa0;
}
.db-icon-moon:before {
  content: "\e90c";
}
.db-icon-notifications:before {
  content: "\e906";
}
.db-icon-language:before {
  content: "\e901";
}
.db-icon-world:before {
  content: "\e953";
}
.db-icon-logout:before {
  content: "\e902";
}
.db-icon-star:before {
  content: "\e947";
}
.db-icon-service .path1:before {
  content: "\e908";
  color: rgb(0, 0, 0);
}
.db-icon-service .path2:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.db-icon-service .path3:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.db-icon-service .path4:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.db-icon-key-milestone .path1:before {
  content: "\e90e";
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.db-icon-key-milestone .path2:before {
  content: "\e90f";
  margin-left: -0.93359375em;
  color: rgb(0, 0, 0);
}
.db-icon-risk .path1:before {
  content: "\e910";
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.db-icon-risk .path2:before {
  content: "\e911";
  margin-left: -0.93359375em;
  color: rgb(0, 0, 0);
}
.db-icon-dependency .path1:before {
  content: "\e922";
  color: rgb(0, 0, 0);
}
.db-icon-dependency .path2:before {
  content: "\e923";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.db-icon-dependency .path3:before {
  content: "\e924";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.db-icon-message .path1:before {
  content: "\e92f";
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.db-icon-message .path2:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.db-icon-deliverables .path1:before {
  content: "\e933";
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.db-icon-deliverables .path2:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.db-icon-benefit .path1:before {
  content: "\e916";
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.db-icon-benefit .path2:before {
  content: "\e917";
  margin-left: -0.93359375em;
  color: rgb(0, 0, 0);
}
.db-icon-escalation .path1:before {
  content: "\e918";
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.db-icon-escalation .path2:before {
  content: "\e919";
  margin-left: -0.93359375em;
  color: rgb(0, 0, 0);
}
.db-icon-raci-matrix .path1:before {
  content: "\e93a";
  color: rgb(0, 0, 0);
}
.db-icon-raci-matrix .path2:before {
  content: "\e93b";
  margin-left: -0.93359375em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.db-icon-raci-matrix .path3:before {
  content: "\e93c";
  margin-left: -0.93359375em;
  color: rgb(0, 0, 0);
}
.db-icon-edit:before {
  content: "\e912";
}
.db-icon-trash:before {
  content: "\e913";
}
.db-icon-download .path1:before {
  content: "\e92a";
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.db-icon-download .path2:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.db-icon-import:before {
  content: "\e925";
}
.db-icon-export:before {
  content: "\e926";
}
.db-icon-upload:before {
  content: "\e949";
}
.db-icon-file:before {
  content: "\e915";
}
.db-icon-sort:before {
  content: "\e920";
}
.db-icon-change-request .path1:before {
  content: "\e935";
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.db-icon-change-request .path2:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.db-icon-plus:before {
  content: "\e921";
}
.db-icon-plus-circle:before {
  content: "\e927";
}
.db-icon-plus-paper:before {
  content: "\e937";
}
.db-icon-minus:before {
  content: "\e94f";
}
.db-icon-chevron-left:before {
  content: "\e91b";
}
.db-icon-chevron-left-circle:before {
  content: "\e91c";
}
.db-icon-chevron-right:before {
  content: "\e91d";
}
.db-icon-chevron-right-circle:before {
  content: "\e91f";
}
.db-icon-chevron-down:before {
  content: "\e91a";
}
.db-icon-arrow-right:before {
  content: "\e903";
}
.db-icon-approve:before {
  content: "\e954";
}
.db-icon-reject:before {
  content: "\e955";
}
.db-icon-loader:before {
  content: "\e948";
}
.db-icon-search:before {
  content: "\e928";
}
.db-icon-bar-3:before {
  content: "\e904";
}
.db-icon-ellipsis:before {
  content: "\e938";
}
.db-icon-history:before {
  content: "\e939";
}
.db-icon-error:before {
  content: "\e93d";
}
.db-icon-warning .path1:before {
  content: "\e941";
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.db-icon-warning .path2:before {
  content: "\e942";
  margin-left: -0.845703125em;
  color: rgb(0, 0, 0);
}
.db-icon-warning .path3:before {
  content: "\e943";
  margin-left: -0.845703125em;
  color: rgb(0, 0, 0);
}
.db-icon-ticket:before {
  content: "\e931";
}
.db-icon-cog:before {
  content: "\e914";
}
.db-icon-configuration:before {
  content: "\e951";
}
.db-icon-services-management:before {
  content: "\e952";
}
.db-icon-user:before {
  content: "\e91e";
}
.db-icon-group:before {
  content: "\e90d";
}
.db-icon-lock:before {
  content: "\e94a";
}
.db-icon-email:before {
  content: "\e94b";
}
.db-icon-phone:before {
  content: "\e94c";
}
.db-icon-eye:before {
  content: "\e94d";
}
.db-icon-eye-off:before {
  content: "\e94e";
}
.db-icon-membership:before {
  content: "\e932";
}
.db-icon-museum-friend:before {
  content: "\e956";
}
.db-icon-map:before {
  content: "\e93e";
}
.db-icon-package-discount:before {
  content: "\e950";
}
.db-icon-passport:before {
  content: "\e93f";
}
.db-icon-collection:before {
  content: "\e929";
}
.db-icon-datepicker:before {
  content: "\e900";
}
.db-icon-calendar:before {
  content: "\e92d";
}
.db-icon-calendar-event:before {
  content: "\e92c";
}
.db-icon-buildings:before {
  content: "\e92e";
}
.db-icon-sheet:before {
  content: "\e940";
}
.db-icon-monitor:before {
  content: "\e944";
}
.db-icon-reservation:before {
  content: "\e945";
}
.db-icon-gate:before {
  content: "\e946";
}
.db-icon-refund:before {
  content: "\e957";
}
.db-icon-filter:before {
  content: "\e958";
}
