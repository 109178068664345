.kiosk-wrapper {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 101%;
    background: url(../../../assets/images/landing/auth.png) center/cover no-repeat;
    filter: blur(2px);
  }

  .kiosk-transparent {
    animation: fade 0.6s;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: rgba($color: $light, $alpha: 0.7);
      filter: blur(2px);
    }

    &.fadeOut {
      animation: fadeOut 0.7s;
    }
  }
}
