@import "mixins";

/* Century-Gothic Fonts */
@font-face {
  font-family: "Century-Gothic-Light";
  font-display: swap;
  src: url("../../fonts/Century-Gothic-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Century-Gothic-Bold";
  font-display: swap;
  src: url("../../fonts/Century-Gothic-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Century-Gothic";
  font-display: swap;
  src: url("../../fonts/Century-Gothic-Regular.ttf") format("truetype");
}

/* Cairo Fonts */
@font-face {
  font-family: "Cairo-Light";
  font-display: swap;
  src: url("../../fonts/Cairo-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Cairo-Bold";
  font-display: swap;
  src: url("../../fonts/Cairo-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Cairo";
  font-display: swap;
  src: url("../../fonts/Cairo-Regular.ttf") format("truetype");
}

@include responsive-font-size();

/* Project Fonts */
$db-font-light: var(--db-font-family-light);
$db-font-regular: var(--db-font-family-regular);
$db-font-bold: var(--db-font-family-bold);
$db-font-size: clamp(0.875rem, 1vw, 1.125rem);

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 500;
  line-height: 1.5 !important;

  &.bold {
    font-weight: 400;
    font-family: $db-font-bold;
  }
}

.fw-bold {
  font-weight: 400 !important;
  font-family: $db-font-bold !important;
}

.font-toggle {
  font-family: "Cairo", sans-serif !important;

  &:dir(rtl) {
    font-family: "Century-Gothic", sans-serif !important;
  }
}
