/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

// regular style toast
@import "ngx-toastr/toastr";

// swiper style
@import "node_modules/swiper/swiper-bundle.min.css";

@import "./assets/icons-font/style.css";

// @import url("https://i.icomoon.io/public/temp/5a76a2204a/UntitledProject/style.css");
