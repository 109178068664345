@import "variables";
@import "typography";
@import "keyframes";

:root {
  --mdc-protected-button-label-text-tracking: normal;
  --mat-datepicker-calendar-header-text-weight: bold;
  --mat-datepicker-calendar-body-label-text-weight: bold;
  --mat-datepicker-calendar-header-text-color: var(--bs-primary);
  --mat-datepicker-calendar-body-label-text-color: var(--bs-primary);
  --mat-datepicker-calendar-date-text-color: var(--bs-primary);
  --mat-form-field-container-text-size: #{$db-font-size};
  --mdc-outlined-text-field-label-text-size: #{$db-font-size};
  --mdc-filled-text-field-label-text-size: #{$db-font-size};
  --mat-form-field-outlined-label-text-populated-size: #{$db-font-size};
  --mat-form-field-subscript-text-size: #{$db-font-size};
  --mat-toolbar-container-background-color: transparent;
  --mat-toolbar-standard-height: 3rem;
  --mat-toolbar-mobile-height: 3rem;
  --mdc-list-list-item-label-text-size: #{$db-font-size};
  --mdc-protected-button-label-text-size: #{$db-font-size};

  --db-font-family-light: "Century-Gothic-Light", sans-serif;
  --db-font-family-regular: "Century-Gothic", sans-serif;
  --db-font-family-bold: "Century-Gothic-Bold", sans-serif;

  --bs-navbar-height: 3rem;
  --bs-font-size: #{$db-font-size};
  --bs-body-text-align: start;
  --bs-middle-rgb: #{to-rgb($middle)};
  --bs-primary-light-rgb: #{to-rgb($primary-light)};
  --tps-box-shadow: 0 7px 10px 0 var(--bs-box-shadow), 0 1px 5px 0 rgba(0, 0, 0, 0.04), 0 1px 5px 0 var(--bs-box-shadow);

  --swiper-navigation-size: 40px;

  &[lang="ar"] {
    --db-font-family-light: "Cairo-Light", sans-serif;
    --db-font-family-regular: "Cairo", sans-serif;
    --db-font-family-bold: "Cairo-Bold", sans-serif;
  }
}

[data-bs-theme="dark"] {
  --bs-body-bg: var(--bs-black);
  --bs-dark: var(--bs-middle);
  --bs-dark-rgb: var(--bs-middle-rgb);
  --bs-dark-gray: var(--bs-light);
  --bs-dark-gray-rgb: var(--bs-light-rgb);
  --bs-white: var(--bs-black);
  --bs-white-rgb: var(--bs-black-rgb);
}
