.border {
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: var(--bs-border-default);

  &.thin {
    --bs-border-width: 0.5px;
  }

  &.thick {
    --bs-border-width: 2px;
  }

  &.primary {
    --bs-border-color: var(--bs-primary);
  }

  &.secondary {
    --bs-border-color: var(--bs-secondary);
  }

  &.black {
    --bs-border-color: rgba(var(--bs-black-rgb), 0.1);
  }

  &.error {
    --bs-border-color: var(--bs-error);
  }

  &.bottom {
    border-inline: none !important;

    &:not(.top) {
      border-top: none !important;
    }
  }

  &.top {
    border-inline: none !important;

    &:not(.bottom) {
      border-bottom: none !important;
    }
  }
}

.border-radius {
  &-0 {
    border-radius: 0;
  }
  &-2 {
    border-radius: 2px;
  }
  &-50 {
    border-radius: 50%;
  }
}
