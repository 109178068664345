.page-wrapper {
  padding-block: 1.5rem;
  padding-inline: clamp(1rem, 5vw, 2.5rem) !important;

  &.mobile-full-width {
    padding: 1.5rem 0 !important;

    @include min-width-screen($min-small) {
      padding: 1.5rem clamp(1rem, 5vw, 2.5rem) !important;
    }
  }

  &.clear-padding {
    padding: 0 !important;
    padding-inline: 0 !important;
  }
}

.text-note {
  color: var(--bs-note) !important;
}

.text-middle {
  color: var(--bs-middle) !important;
}

.text-dark-gray {
  color: var(--bs-dark-gray) !important;
}

.text-dark-red {
  color: $dark-red !important;
}

.text-green {
  color: var(--bs-green) !important;
}

.text-orange {
  color: var(--bs-orange) !important;
}

.bg-orange {
  background-color: var(--bs-orange) !important;
}

.bg-dark-red {
  background-color: $dark-red !important;
}

.bg-primary-light {
  background-color: var(--bs-primary-light) !important;
}

.bg-white-50 {
  background: rgba($color: $white, $alpha: 0.5) !important;

  @include dark-mode() {
    background: rgba($color: $middle, $alpha: 0.5) !important;
  }
}

.alert-wrapper {
  height: 55px;
  top: 1rem;
  left: 1rem;

  &:dir(rtl) {
    left: unset;
    right: 1rem;
  }

  .alert-text {
    max-width: 25vw;
  }
}

.alert-dark-red {
  --bs-alert-color: var(--bs-white) !important;
  --bs-alert-bg: #{$dark-red} !important;
  --bs-alert-border-color: transparent !important;
  --bs-alert-link-color: var(--bs-white) !important;
  --bs-alert-border-radius: 0 !important;
}

.page-title {
  font-size: clamp(1.2rem, 1.9vw, 2.5rem) !important;
}

.rotate {
  &:dir(ltr) {
    display: block;
    transform: rotate(180deg);
  }
}

.rotate-45 {
  transform: rotate(45deg);
}

.z-index-5 {
  z-index: 5;
}

.text-truncate-3 {
  display: -webkit-box;
  display: -moz-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

.form-group label {
  margin: 0;
}

hr {
  border-color: var(--bs-primary) !important;
  opacity: 1 !important;
}

.border-orange {
  --bs-border-color: var(--bs-orange) !important;
}

.section-bg {
  background-color: rgba($color: $black, $alpha: 0.85);

  @media (prefers-color-scheme: light) {
    background-image: url("../../../assets/images/section-bg.jpeg");
    background-size: 100%;
  }
}
