@import "base/index";
@import "basics/index";
@import "configurations/index.scss";

/* Scrollbar Styles Start Here*/
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, $secondary, $primary) !important;
  border-radius: 8px;
}

* {
  scrollbar-color: linear-gradient(to bottom, $secondary, $primary) transparent;
}
