@keyframes fade {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0.5);
  }
}

@keyframes move {
  0% {
    top: 50px;
    opacity: 0;
    transform: scale(0.5);
  }

  100% {
    top: 0;
    opacity: 1;
    transform: scale(1);
  }
}
