.mat-stepper-vertical,
.mat-stepper-horizontal {
  --mat-stepper-container-color: transparent;

  .mat-step-header {
    &.cdk-keyboard-focused,
    &.mat-step-header.cdk-program-focused {
      --mat-stepper-header-focus-state-layer-color: transparent;
    }

    .mat-step-icon {
      --mat-stepper-header-icon-foreground-color: #{$primary};
      --mat-stepper-header-icon-background-color: #{$white};
      border: 1.5px solid $primary;
      width: 2rem;
      height: 2rem;
    }

    .mat-step-label {
      .mat-step-text-label {
        color: $primary;
        transition: all 0.3s ease;
      }

      &-selected {
        .mat-step-text-label {
          font-weight: bold;
          font-family: $db-font-bold;
        }
      }
    }
  }

  .mat-vertical-content {
    padding: 0;
  }
}

.mat-stepper-horizontal {
  .mat-horizontal-stepper-header-container {
    padding: 0 24px;
    align-items: flex-start;

    @include min-width-screen($min-large) {
      padding: 0 40px;
    }

    .mat-horizontal-stepper-header {
      flex-direction: column;
      justify-content: space-between;
      padding: 0;

      .mat-step-icon {
        margin: 0;
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    .mat-stepper-horizontal-line {
      --mat-stepper-line-color: #d1d1d1;
      border-top-width: 2px;
      margin-top: 1.2rem;
      margin-inline: -50px;
    }
  }
}

.mat-stepper-vertical {
  width: calc(100vw - (clamp(1rem, 5vw, 2.5rem) * 2) - 6px);

  @include min-width-screen($min-small) {
    width: calc(100vw - (clamp(1rem, 5vw, 2.5rem) * 4) - 76px);
  }
}
