// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

/* For use in src/lib/core/theming/_palette.scss */
$md_primary_palette: (
  main: $primary,
  lighter: $primary-light,
  darker: $primary-dark,
  200: $primary-light,
  contrast: (
    main: #fff,
    lighter: #000,
    darker: #fff,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$md_accent_palette: (
  main: $secondary,
  lighter: $secondary-light,
  darker: $secondary-dark,
  200: $secondary-light,
  contrast: (
    main: #fff,
    lighter: #000,
    darker: #fff,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$md_warn_palette: (
  main: $error,
  lighter: #fac7c7,
  darker: #eb3535,
  200: $error-light,
  contrast: (
    main: #fff,
    lighter: #000,
    darker: #fff,
  ),
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$NationalMuseumBooking-primary: mat.define-palette($md_primary_palette, main, lighter, darker);
$NationalMuseumBooking-accent: mat.define-palette($md_accent_palette, main, lighter, darker);

// The warn palette is optional (defaults to red).
$NationalMuseumBooking-warn: mat.define-palette($md_warn_palette, main, lighter, darker);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$NationalMuseumBooking-light-theme: mat.define-light-theme(
  (
    color: (
      primary: $NationalMuseumBooking-primary,
      accent: $NationalMuseumBooking-accent,
      warn: $NationalMuseumBooking-warn,
    ),
    is-dark: false,
    typography:
      mat.define-typography-config(
        $font-family: $db-font-regular,
      ),
  )
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$NationalMuseumBooking-dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $NationalMuseumBooking-primary,
      accent: $NationalMuseumBooking-accent,
      warn: $NationalMuseumBooking-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@include mat.all-component-themes($NationalMuseumBooking-light-theme);

@media (prefers-color-scheme: dark) {
  @include mat.all-component-colors($NationalMuseumBooking-dark-theme);

  html {
    --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.25);
  }
}
