* {
  letter-spacing: normal;
}

a {
  text-decoration: none !important;
  color: inherit;
  outline: none;

  &:hover {
    color: $primary;
  }
}

html {
  --mdc-plain-tooltip-container-color: #{$primary} !important;
}

body {
  .cdk-global-overlay-wrapper {
    direction: ltr !important;
  }

  &.rtl {
    direction: rtl;

    .cdk-global-overlay-wrapper,
    mat-tooltip-component,
    mat-datepicker-content.mat-datepicker-content {
      direction: rtl !important;
    }

    .cdk-overlay-connected-position-bounding-box {
      &:has(.dropdown-panel) {
        direction: rtl !important;
      }
    }
  }

  [class^="db-icon-"],
  [class*=" db-icon-"] {
    *::before {
      color: inherit !important;
    }
  }
}

.db-icon-plus.remove {
  display: block;
  rotate: 45deg;
}

// mat-calendar
.custom-date {
  .mat-calendar-body-cell-content {
    border-radius: 100%;
    background-color: $primary;
  }

  * {
    &:not(:hover) {
      color: $white !important;
    }
  }
}

// mat-datepicker
.mat-calendar-body-cell-content {
  font-weight: bold;
}

// mat-divider
.mat-divider {
  --mat-divider-color: var(--bs-primary);
  height: 1px;
}

app-book {
  .mat-mdc-text-field-wrapper,
  .quantity-input {
    background-color: var(--bs-white) !important;
  }
}

.mat-calendar-table-header-divider::after {
  height: 2px !important;
  background: var(--mat-datepicker-calendar-header-text-color) !important;
}
